"use client";

import { ModalContainer } from "@/app/ui-kit/containers/model-wrapper";
import { queryClientOptions } from "@/infrastructure/utils/constants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
}

export const Providers = ({ children }: Props) => {
  const [queryClient] = useState(() => new QueryClient(queryClientOptions));

  return (
    <QueryClientProvider client={queryClient}>
      <ModalContainer>{children}</ModalContainer>
    </QueryClientProvider>
  );
};
